
import dayjs from "dayjs";
import { Component, Vue, namespace } from "nuxt-property-decorator";

import { generateMetaTags } from "@/operations/meta-helper-functions";
import { Descriptions, PaymentTypes } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import StartPageBanner from "~/components/molecules/banner/StartPageBanner.vue";
import CheckMark from "~/components/molecules/display/CheckMark.vue";
import LightBox from "~/components/molecules/display/LightBox.vue";
import PriceTrendVote from "~/components/molecules/form/PriceTrendVote.vue";
import BlogPost from "~/components/molecules/text/BlogPost.vue";
import PartnerTile from "~/components/molecules/text/PartnerTile.vue";
import UserComment from "~/components/molecules/text/UserComment.vue";
import CombinedRecommendationCharts from "~/components/organisms/chart/CombinedRecommendationCharts.vue";
import PriceTrendChart from "~/components/organisms/chart/PriceTrendChart.vue";
import PreisanteileHeizoel from "~/components/organisms/display/PreisanteileHeizoel.vue";
import TrendDataTile from "~/components/organisms/display/TrendDataTile.vue";
import NewsletterSignup from "~/components/organisms/form/NewsletterSignup.vue";
import PriceNotification from "~/components/organisms/form/PriceNotification.vue";
import sendGtmEvent from "~/operations/send-gtm-event";

// Mixins
import { Jsonld } from "nuxt-jsonld";
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import {
  getCurrentDate,
  getFormattedPercentage,
  getPriceDifference,
  returPriceTrendFormatDE,
  returnFormattedDate,
  returnPriceFormatDE,
} from "~/mixins/formatting";
import { decodeUrlForPlace } from "~/operations/shared";

const FederalstatesModule = namespace("federalstates");
const EsyoilModule = namespace("esyoil");
const BlogModule = namespace("blog");
const BreadCrumbModule = namespace("breadcrumb");

@Jsonld
@Component({
  components: {
    RouterLink,
    CombinedRecommendationCharts,
    PriceTrendChart,
    TrendDataTile,
    BlogPost,
    StartPageBanner,
    NewsletterSignup,
    PriceNotification,
    PriceTrendVote,
    UserComment,
    PartnerTile,
    LightBox,
    CheckMark,
    PreisanteileHeizoel,
    AkkordeonListItem,
  },
  methods: {
    returPriceTrendFormatDE,
    returnPriceFormatDE,
    returnFormattedDate,
    decodeUrlForPlace,
  },
})
export default class IndexPage extends Vue {
  jsonld() {
    return {
      "@context": "http://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Was kostet 1 Liter Heizöl heute?",
          acceptedAnswer: {
            "@type": "Answer",
            text: this.questList[0].answer,
          },
        },
        {
          "@type": "Question",
          name: "Ist die Heizölpreis-Tendenz fallend oder steigend?",
          acceptedAnswer: {
            "@type": "Answer",
            text: this.questList[1].answer,
          },
        },
        {
          "@type": "Question",
          name: "Wann ist der beste Zeitpunk zum Heizölkauf?",
          acceptedAnswer: {
            "@type": "Answer",
            text: this.questList[2].answer,
          },
        },
        {
          "@type": "Question",
          name: "Bei welchem Händler soll ich Heizöl kaufen?",
          acceptedAnswer: {
            "@type": "Answer",
            text: this.questList[3].answer,
          },
        },

        {
          "@type": "Question",
          name: "Wie schnell kann ich Heizöl bekommen?",
          acceptedAnswer: {
            "@type": "Answer",
            text: this.questList[4].answer,
          },
        },
      ],
    };
  }
  @EsyoilModule.State("newsletterSignupStatus")
  newsletterSignupStatus;
  Descriptions = Descriptions;
  PaymentTypes = PaymentTypes;
  dayjs = dayjs;

  showConfirmModal = false;
  showInfoModal = false;

  @EsyoilModule.State("userPriceTrendVoting")
  userPriceTrendVoting;

  @EsyoilModule.Action("fetchUserPriceTrendVoting")
  fetchUserPriceTrendVoting;

  @BlogModule.State("newestPostID")
  newestPostID;

  // eslint-disable-next-line no-undef
  STAGE = process.env.STAGE;
  // eslint-disable-next-line no-undef
  COMMIT_HASH = process.env.COMMIT_HASH;
  // eslint-disable-next-line no-undef
  COMMIT_MESSAGE = process.env.COMMIT_MESSAGE;

  serviceStepsList = [
    {
      image: "piggy-bank.svg",
      title: "Heizölpreise vergleichen",
      iconTitle: "Aktuellen Heizölpreise in der Nähe vergleichen",
      text: "Überblick über alle aktuellen Heizölpreise in der Nähe.",
    },
    {
      image: "order.svg",
      title: "Heizöl 24/7 online bestellen",
      iconTitle: "Heizöl online bestellen",
      text: "Rund um die Uhr, einfach, schnell und bequem",
    },
    {
      image: "delivery.svg",
      title: "Lieferung erhalten",
      iconTitle: "Heizöl von Händlern in der Nähe geliefert bekommen",
      text: "Qualitätsheizöl von Händlern aus Ihrer Region.",
    },
  ];

  get questList() {
    const currentPriceValue =
      this.$store.state.oilprice.stateAssetsPrice.currentPrice.value;
    const lastPriceValue =
      this.$store.state.oilprice.stateAssetsPrice.lastPrice.value;
    const formattedCurrentPrice = returnPriceFormatDE(
      this.$store.state.oilprice.stateAssetsPrice.currentPrice.value,
    );
    const currentDate = getCurrentDate();
    const { difference, numericCurrentPrice, numericLastPrice } =
      getPriceDifference(currentPriceValue, lastPriceValue);
    const { priceStatus, priceVariation } =
      numericCurrentPrice > numericLastPrice
        ? {
            priceStatus: "mehr",
            priceVariation: `einem Preisanstieg von ${getFormattedPercentage(
              currentPriceValue,
              lastPriceValue,
            )}`,
          }
        : {
            priceStatus: "weniger",
            priceVariation: `einer Preissenkung von ${getFormattedPercentage(
              currentPriceValue,
              lastPriceValue,
            )}`,
          };

    const positivesVotesPercentage =
      this.userPriceTrendVoting.positiveVotesPercentage.toString();
    const negativeVotesPercentage =
      this.userPriceTrendVoting.negativeVotesPercentage.toString();

    let percentageAnswer;
    let trend;

    if (positivesVotesPercentage >= negativeVotesPercentage) {
      percentageAnswer = positivesVotesPercentage;
      trend = "fällt";
    } else {
      percentageAnswer = negativeVotesPercentage;
      trend = "steigt";
    }

    this.QuestionsList = [
      {
        question: "Was kostet 1 Liter Heizöl heute?",
        answer: `Der Liter Heizöl kostet am ${currentDate} im deutschlandweiten Durchschnitt ${formattedCurrentPrice.replace(
          "€",
          "",
        )}Cent. Das sind ${String(difference / 100).replace(
          ".",
          ",",
        )} Cent ${priceStatus} als am Vortag. Das entspricht ${priceVariation}.`,
        visibility: true,
      },
      {
        question: "Ist die Heizölpreis-Tendenz fallend oder steigend?",
        answer: `Heizölpreis Entwicklung: Die Mehrheit (${percentageAnswer}%) unserer Nutzer geht davon aus, dass der Heizölpreis ${trend}. Die Kaufbereitschaft der esyoil-Kunden ist zurzeit ${this.captalizeFirstLetter(
          this.$store.state.schwarmometer.userSignal.buyingIntesionString,
        )}. Die Empfehlung unseres Tiefpreis-Systems lautet: ${
          this.stringValue
        }`,
        visibility: false,
      },
      {
        question: "Wann ist der beste Zeitpunkt zum Heizölkauf?",
        answer:
          "Eine klare Kaufempfehlung kann man bezüglich des Heizölpreises nicht geben, da die Einflussfaktoren auf den Ölpreis vielfältig sind. Um sich jedoch auf den aktuellen Stand zu bringen, können Sie den täglichen Kommentar auf unserer esyoil-Homepage lesen. Zusätzlich schaffen unsere esyoil-Kaufempfehlung sowie unser Schwarm-O-Meter eine gute Orientierung.",
        visibility: false,
      },
      {
        question: "Bei welchem Händler soll ich Heizöl kaufen?",
        answer: `Für die Ölbestellung haben Sie bei dem esyoil Heizöl Preisvergleich die Auswahl zwischen verschiedenen regionalen Händlern, um Ihre Heizölpreise in der Nähe zu finden. Bei dem ersten vorgeschlagenen Händler handelt es sich um den Günstigsten. Sie können jedoch auch Ihre Auswahl nach der kürzesten Lieferzeit oder der besten Bewertung filtern.`,
        visibility: false,
      },
      {
        question: "Wie schnell kann ich Heizöl bekommen?",
        answer:
          "Bei einer Ölbestellung haben Sie die Möglichkeit, verschiedene Lieferoptionen auszuwählen. Bei einer Ölbestellung ohne Lieferoption liefert der Händler regional innerhalb der angegebenen Lieferzeit. Zusätzlich können Sie die Lieferoptionen 6-10 Tage, 3-5 Tage, Express-Lieferung, 24 Stunden-Lieferung oder heute liefern auswählen.",
        visibility: false,
      },
    ];
    return this.QuestionsList;
  }

  bannerContents = [
    {
      image: "piggy-bank-women.webp",
      fallbackImage: "piggy-bank-women.jpg",
      href: { path: "/infos/ratenkauf" },
      title: `esyoil-${PaymentTypes.guaranteedPayment}`,
      subTitle: "sicher & flexibel.",
      text: `Der esyoil-${PaymentTypes.guaranteedPayment} ist die ideale Lösung, um gute Kaufmomente zu nutzen - auch wenn der Heizölkauf gerade nicht geplant war.`,
    },
    {
      image: "heater-house.webp",
      fallbackImage: "heater-house.jpg",
      href: { path: "/services/oelheizung" },
      title: "Die Zukunft der Ölheizung",
      subTitle: "Jetzt informieren",
      text: "Verbot von Ölheizungen? Austauschpflicht? esyoil schafft Klarheit!",
    },
  ];

  bannerPickContent = {
    image: "piggy-bank-women.webp",
    fallbackImage: "piggy-bank-women.jpg",
    href: { path: "/infos/ratenkauf" },
    title: `esyoil-${PaymentTypes.guaranteedPayment}`,
    subTitle: "sicher & flexibel.",
    text: `Der esyoil-${PaymentTypes.guaranteedPayment} ist die ideale Lösung, um gute Kaufmomente zu nutzen - auch wenn der Heizölkauf gerade nicht geplant war.`,
  };

  @FederalstatesModule.State("topStatePartners")
  topStatePartners: any;

  head() {
    return {
      ...generateMetaTags(this.$route.path, "index"),
      link: [
        {
          rel: "alternate",
          href: "/heizoel_rss.php",
          type: "application/rss+xml",
          title: "Heizölnews von esyoil",
        },
        { rel: "canonical", href: `https://www.esyoil.com` },
      ],
    };
  }

  QuestionsList = [];

  get day() {
    return dayjs().format("DD.MM.YYYY HH:mm");
  }

  async fetch() {
    await Promise.all([
      this.fetchPriceTrendData(),
      this.fetchBlogPostData(),
      this.$store.dispatch("schwarmometer/fetchUserSignal"),
      this.$store.dispatch("schwarmometer/fetchEsyoilSignal", ""),
    ]);
  }

  async mounted() {
    window["gtag_enable_tcf_support"] = true;
    await Promise.all([
      this.fetchDistrictPriceTrends(),
      this.fetchCityPriceTrends(),
      this.fetchPartnersAndRatingyByZipcode(this.tryLocalStorageUserZipCode()),
      this.pickBannerContent(),
      this.initiallyCheckForVotingPermission(
        this.$store.state.blog.articles[0]?.id,
      ),
      this.fetchUserPriceTrendVoting(this.newestPostID),
    ]);

    this.showConfirmModal =
      Object.keys(this.$route.query).includes("confirm") ||
      Object.keys(this.$route.query).includes("disable");

    if (this.STAGE == "develop")
      document.onkeydown = (e) => {
        var evtobj: any = window.event ? event : e;
        if (evtobj.keyCode == 73 && evtobj.ctrlKey) this.showInfoModal = true;
      };
  }

  pickBannerContent() {
    const BannerPickIndex = Math.floor(Math.random() * 2);
    this.bannerPickContent = this.bannerContents[BannerPickIndex];
  }

  makeWordBold(sentence: string): string {
    const words = sentence.split(" ");
    const targetWords = [
      "esyoil-Kaufempfehlung",
      "Schwarm-O-Meter",
      "Hoch.",
      "Mittel.",
      "Gering.",
      "Kaufen",
      "Neutral",
      "Cent.",
    ];

    words.forEach((word: string, index: number) => {
      if (targetWords.includes(word)) {
        words[index] = '<b style="font-weight: bold;">' + word + "</b>";
      } else if (/\b\d{2},\d{2}\b(?!.\d)/.test(word)) {
        // Check if the word matches the format "66,44" and doesn't have any additional digits after comma
        words[index] = '<b style="font-weight: bold;">' + word + "</b>";
      }
    });

    return words.join(" ");
  }

  captalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  tryLocalStorageUserZipCode() {
    if (
      localStorage.getItem("userZipCode") !== null &&
      localStorage.getItem("userZipCode") !== "" &&
      localStorage.getItem("userZipCode").length >= 4
    ) {
      return localStorage.getItem("userZipCode");
    }

    return "00000";
  }

  returnValidMapPriceMarkers(districtName) {
    const IndexedDistricts = [
      "Hamburg",
      "Berlin",
      "Rheinland-Pfalz",
      "Bayern",
      "Baden-Württemberg",
      "Hessen",
      "Nordrhein-Westfalen",
    ];
    if (IndexedDistricts.includes(districtName)) {
      return true;
    }
    return false;
  }

  async fetchPartnersAndRatingyByZipcode(zipcode) {
    await this.$store.dispatch(
      "federalstates/fetchPartnersAndRatingyByZipcode",
      zipcode,
    );
  }

  async fetchPriceTrendData() {
    await this.$store.dispatch("oilprice/fetchNationalPriceTrend");
  }

  async initiallyCheckForVotingPermission(articleID) {
    await this.$store.dispatch("esyoil/checkUserVotingPermission", articleID);
  }

  checkForVotingPermission(): boolean {
    return !this.$store.state.esyoil.pricePredictionVoting.userHasVoted;
  }

  async fetchDistrictPriceTrends() {
    await this.$store.dispatch("oilprice/fetchDistrictPriceTrend");
  }

  async fetchCityPriceTrends() {
    await this.$store.dispatch("oilprice/fetchAverageCityPrices");
  }

  async fetchBlogPostData() {
    await Promise.all([
      this.$store.dispatch("blog/fetchAuthorsData"),
      this.$store.dispatch("blog/fetchNewestBlogPosts"),
    ]);
  }

  splitNumberFromCurrency(value: string) {
    return value.split(" ");
  }

  get getVotingId() {
    if (this.$store.state.blog.articles[0]) {
      return this.$store.state.blog.articles[0].id;
    }
    return 0;
  }

  get stringValue() {
    return this.captalizeFirstLetter(
      this.$store.state.schwarmometer.esyoilSignal.buyingIntesionString,
    );
  }

  tracking(val, item) {
    this.QuestionsList.map((questionItem) => {
      if (questionItem.question === item.question) {
        questionItem.visibility = true;
      } else {
        questionItem.visibility = false;
      }
      return questionItem;
    });

    // If visible then track event
    if (val.visibility) {
      sendGtmEvent(this, {
        category: "FAQ",
        label: item.question,
        action: "click",
      });
    }
  }
}
