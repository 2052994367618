
// Libraries
import { Component, Vue, Prop } from "nuxt-property-decorator";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import SummedRating from "~/components/molecules/display/ratings/SummedRating.vue";
import AuthorImage from "~/components/molecules/embedding/AuthorImage.vue";

@Component({
  components: {
    RouterLink,
    SummedRating,
    AuthorImage,
  },
})
export default class UserCommentMolecule extends Vue {
  @Prop({ default: "company" }) name: string;
  @Prop({ required: true }) slug: string;
  @Prop({ required: true }) profileSlug: string;
  @Prop({ default: 0 }) rating: number;
  @Prop({ default: 0 }) ratingQuantity: number;
  @Prop({
    default: require("~/assets/images/placeholders/partner-logo-placeholder.svg"),
  })
  imageURL: string;
}
